.layout-sidebar {
    position: fixed;
    width: 300px;
    height: calc(100vh - 9rem);
    z-index: 999;
    overflow-y: auto;
    user-select: none;
    top: 7rem;
    left: 2rem;
    transition: transform $transitionDuration, left $transitionDuration;
    background-color: var(--surface-overlay);
    border-radius: $borderRadius;
    padding: 0.5rem 1.5rem;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, .02), 0px 0px 2px rgba(0, 0, 0, .05), 0px 1px 4px rgba(0, 0, 0, .08);
}

.layout-menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
  height: 75%;

  .layout-root-menuitem {
    > .layout-menuitem-root-text {
      font-size: .857rem;
      text-transform: uppercase;
      font-weight: 700;
      color: var(--surface-900);
      margin: .75rem 0;
      text-align: left;
    }

    > a {
      display: none;
    }
  }

  a {
    user-select: none;

    &.active-menuitem {
      > .layout-submenu-toggler {
        transform: rotate(-180deg);
      }
    }
  }

  li.active-menuitem {
    > a {
      .layout-submenu-toggler {
        transform: rotate(-180deg);
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    a {
      display: flex;
      align-items: center;
      position: relative;
      outline: 0 none;
      color: var(--text-color);
      cursor: pointer;
      padding: .75rem 1rem;
      border-radius: $borderRadius;
      transition: background-color $transitionDuration, box-shadow $transitionDuration;

      .layout-menuitem-icon {
        margin-right: .5rem;
      }

      .layout-submenu-toggler {
        font-size: 75%;
        margin-left: auto;
        transition: transform $transitionDuration;
      }

      &.active-route {
        font-weight: 700;
        color: var(--primary-color);
      }

      &:hover {
        background-color: var(--surface-hover);
      }

      &:focus {
        @include focused-inset();
      }
    }

    ul {
      overflow: hidden;
      border-radius: $borderRadius;

      li {
        a {
          margin-left: 1rem;
        }

        li {
          a {
            margin-left: 2rem;
          }

          li {
            a {
              margin-left: 2.5rem;
            }

            li {
              a {
                margin-left: 3rem;
              }

              li {
                a {
                  margin-left: 3.5rem;
                }

                li {
                  a {
                    margin-left: 4rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.imgFlyStore {
  width: 40%;
  margin: auto;
}


.store {
  display: flex;
  align-items: center;
  border-radius: 25px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: black;
  width: 10em;
  margin-right: 5px;
  cursor: pointer;
}

.store img.imgStore {
  height: 30px;
  width: 30px;
  object-fit: cover;
}

.store > div {
  color: white;
  padding: 0px 0px;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
}



