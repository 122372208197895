
@import "../node_modules/primeng/resources/themes/lara-light-blue/theme.css";
@import "../node_modules/primeng/resources/primeng.css";
@import 'quill/dist/quill.core.css'; // Importez les styles CSS de Quill si nécessaire.
@import 'quill/dist/quill.snow.css'; // Importez les styles du thème "snow" de Quill si nécessaire.

@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

/* Utilise le thème sombre si l'utilisateur a une préférence pour un thème sombre */
@media (prefers-color-scheme: dark) {
  //@import "../node_modules/primeng/resources/themes/lara-dark-blue/theme.css";
}

/*
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-image-editor/styles/material.css";
*/

// "primeflex/themes/primeone-light.css",
// "primeflex/themes/primeone-dark.css"

/*.layout-menu-button {
  position: absolute;
  top: 6em;
  left: 1.5em;
  width: 3em;
  height: 2em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}*/

html, body {
  height: 100% !important;
  margin: 0 !important;
  background-color: #eff3f8 !important;
  overflow: hidden !important;
}


.pi-sign-in {
  transform: rotate3d(1, 1, 11, 180deg);
}

/*body {
  background-color: #eff3f8 !important;
  margin: 0 !important;
  padding: 0 !important;
  min-height: 100% !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}*/

h1 {
  color:white;
  font-style: normal;
  font-weight: bold;
}

a {
  text-decoration: none !important;
}

p-table {
  width: 100% !important;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
