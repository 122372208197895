@import './_variables';
@import "./_mixins";
@import "./_preloading";
@import "./_main";
@import "./_topbar";
@import "./_menu";
@import "./_config";
@import "./_content";
@import "./_footer";
@import "./_responsive";
@import "./_utils";
@import "./_typography";

.p-breadcrumb {
  border: none !important;
  padding: 0 !important;
  background: transparent !important;
  margin-left: 1em !important;
  margin-bottom: 5px !important;
}

/* CSS pour ajouter de l'espace entre l'icône de la maison et le texte "Accueil" dans le fil d'Ariane */
.p-breadcrumb .p-menuitem-icon {
  margin-right: 5px; /* Ajustez l'espacement selon vos besoins */
}
