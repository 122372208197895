.layout-topbar {
  position: fixed;
  height: 5rem;
  z-index: 997;
  left: 0;
  top: 0;
  width: 100%;
  padding: 0 2rem;
  background-color: var(--surface-card);
  transition: left $transitionDuration;
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 5px rgba(0,0,0,.02), 0px 0px 2px rgba(0,0,0,.05), 0px 1px 4px rgba(0,0,0,.08);

  .layout-topbar-logo {
    display: flex;
    align-items: center;
    color: var(--surface-900);
    font-size: 1.5rem;
    font-weight: 500;
    width: 8em;
    border-radius: 12px;

    img {
      height: 2.5rem;
      margin-right: .5rem;
    }

    &:focus {
      @include focused();
    }
  }

  .layout-topbar-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: var(--text-color-secondary);
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    transition: background-color $transitionDuration;

    &:hover {
      color: var(--text-color);
      background-color: var(--surface-hover);
    }

    &:focus {
      @include focused();
    }

    i {
      font-size: 1.5rem;
    }

    span {
      font-size: 1rem;
      display: none;
    }
  }

  .layout-menu-button {
    margin-left: 2rem;
  }

  .layout-topbar-menu-button {
    display: none;

    i {
      font-size: 1.25rem;
    }
  }

  .layout-topbar-menu {
    margin: 0 0 0 auto;
    padding: 0;
    list-style: none;
    display: flex;

    .layout-topbar-button {
      margin-left: 1rem;
    }
  }
}

@media (max-width: 991px) {
    .layout-topbar {
        justify-content: space-between;

        .layout-topbar-logo {
            width: auto;
            order: 2;
        }

        .layout-menu-button {
            margin-left: 0;
            order: 1;
        }

        .layout-topbar-menu-button {
            display: inline-flex;
            margin-left: 0;
            order: 3;
        }

        .layout-topbar-menu {
            margin-left: 0;
            position: absolute;
            flex-direction: column;
            background-color: var(--surface-overlay);
            box-shadow: 0px 3px 5px rgba(0,0,0,.02), 0px 0px 2px rgba(0,0,0,.05), 0px 1px 4px rgba(0,0,0,.08);
            border-radius: 12px;
            padding: 1rem;
            right: 2rem;
            top: 5rem;
            min-width: 15rem;
            display: none;
            -webkit-animation: scalein 0.15s linear;
            animation: scalein 0.15s linear;

            &.layout-topbar-menu-mobile-active {
                display: block
            }

            .layout-topbar-button {
                margin-left: 0;
                display: flex;
                width: 100%;
                height: auto;
                justify-content: flex-start;
                border-radius: 12px;
                padding: 1rem;

                i {
                    font-size: 1rem;
                    margin-right: .5rem;
                }

                span {
                    font-weight: medium;
                    display: block;
                }
            }
        }
    }
}

.p-dropdown-panel {
  position: absolute !important;
  z-index: 1000;
}

/* Tooltip */
.tooltipDiv-container {
  position: relative;
}

.tooltipDiv {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 3em; /* Positionne la tooltip juste en dessous de l'icône */
  left: 50%;
  transform: translateX(-50%); /* Centre la tooltip horizontalement par rapport à l'icône */
  padding: 0.625em;
  background-color: #333;
  color: #fff;
  border-radius: 0.625em;
  box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.2);
  width: 20em;
  text-align: center;
  z-index: 1;
}

.tooltipDiv::after { /* La "petite pique" dirigée vers le bouton */
  content: '';
  position: absolute;
  top: -0.625em; /* Positionne la "petite pique" juste au-dessus de la tooltip */
  left: 50%;
  transform: translateX(-50%);
  border-left: 0.625em solid transparent;
  border-right: 0.625em solid transparent;
  border-top: 0.625em solid #333; /* La même couleur que la tooltip */
}

.tooltipDivCamera {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 3em; /* Positionne la tooltip juste en dessous de l'icône */
  right: -17em;
  transform: translateX(-50%); /* Centre la tooltip horizontalement par rapport à l'icône */
  padding: 0.625em;
  background-color: #333;
  color: #fff;
  border-radius: 0.625em;
  box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.2);
  text-align: center;
  z-index: 1;
  width: 26em;
}

.tooltipDivCamera::after { /* La "petite pique" dirigée vers le bouton */
  content: '';
  position: absolute;
  top: -0.625em; /* Positionne la "petite pique" juste au-dessus de la tooltip */
  right: 3.8em;
  transform: translateX(-50%);
  border-left: 0.625em solid transparent;
  border-right: 0.625em solid transparent;
  border-top: 0.625em solid #333; /* La même couleur que la tooltip */
}

.phraseQrCode {
  margin-bottom: 1em;
}

.qrCode img {
  border-radius: 16px;
}

fieldset.scheduler-border {
  border: 1px groove #ddd !important;
  padding: 0 1.4em 1.4em 1.4em !important;
  /* margin: 0 0 1.5em 0 !important; */
  box-shadow: 0px 0px 0px 0px #000;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 20em;
}

.donneeFielDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 80%;
}
